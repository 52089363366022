<template>
  <div id="attribute">
    <v-container
      fluid
      class="pa-0">
      <v-row>
        <v-col
          :sm="3"
          :cols="12">
          <v-list class="rounded elevation-3">
            <v-list-item-group>
              <list-menu
                v-for="(menu, index) in menus"
                :key="`${menu.title}-${index}`"
                :title="menu.title"
                :to="menu.to"
                :icon="menu.icon"
                path="menu.attribute"
                icon-prepend />
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col
          :sm="9"
          :cols="12">
          <v-card class="pa-sm-3 attribute-view">
            <transition
              name="slide-fade"
              mode="out-in">
              <router-view />
            </transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import listMenu from '@/components/menu/ListMenu.vue'
import { getMenuByRole } from '@/assets/js/Authentication'
import prototypeattributesMenu from '@/assets/js/menu/PrototypeAttributes'

export default {
  components: {
    listMenu
  },
  computed: {
    menus () {
      return getMenuByRole(prototypeattributesMenu, this.$router)
    }
  }
}
</script>

<style scoped>
#attribute,
#attribute > .container,
#attribute > .container > .row {
  height: 100%;
}
.attribute-view {
  min-height: 90vh;
}
</style>
