export default [
  {
    title: 'Factories',
    icon: 'mdi-factory',
    to: 'FactoryManagement'
  },
  {
    title: 'Fabric Factories',
    icon: 'mdi-factory',
    to: 'FabricFactoryManagement'
  },
  {
    title: 'Material Factories',
    icon: 'mdi-factory',
    to: 'MaterialFactoryManagement'
  },
  {
    title: 'Merchandisers',
    icon: 'mdi-account-cog-outline',
    to: 'MerchandiserManagement'
  },
  {
    title: 'Designers',
    icon: 'mdi-account-cog-outline',
    to: 'DesignerManagement'
  },
  {
    title: 'Graphics',
    icon: 'mdi-account-cog-outline',
    to: 'GraphicManagement'
  },
  {
    title: 'Creative',
    icon: 'mdi-account-cog-outline',
    to: 'StylistManagement'
  },
  {
    title: 'Sub-categories',
    icon: 'mdi-shape-outline',
    to: 'SubCategoryManagement'
  }
]
